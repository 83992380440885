#headder {
  display: flex;
  flex-direction: column;
  height: 25vh;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(0, 0, 0);
}

#logo {
  display: flex;
  height: 20vh;
  overflow: hidden;
  align-items: center;
}

#logo img{
  width:100vw;
  height:15vh;

}

#menu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 5vh;
  width: 100%;
  overflow: hidden;
}

.menuOption {
  cursor: pointer;
  color:white;
  transition: transform 0.5s ease-in-out, text-shadow 0.3s ease-in-out;

}

.menuOption:hover {
  cursor: pointer;
  color: #ff0000;
  transform: translate(5px, -5px); /* Unoszenie się po najechaniu myszką */
  text-shadow: 5px 5px 10px rgb(255, 0, 0); /* Dodaj cień po najechaniu myszką */
  
}

#mainSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  overflow: scroll;
}

#footer {
  display: flex;
  flex-direction: column;
  height: 5vh;
  background-color: rgb(0, 0, 0);
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.footerText{
  color:white;
}