.Post {
    display: flex;
    flex-direction: row;
    max-height: 60vh;
    margin: 4% 4% 0 0;
    margin-left: 6%;
    padding: 2%;;
    box-shadow: 30px 10px 10px rgba(0, 0, 0, 0.3);
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    transition: border 0.4s ease-in-out, transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
    cursor: pointer;

}

.PostHovered{
    border: solid rgba(255, 0, 0, 0.646) 2px;
    transform: translate(-25px, -25px);
    box-shadow: 55px 35px 20px rgba(65, 35, 35, 0.3);
}

.postImage{
    width: 30%;
    margin: 1%;
    border-radius: 30px;
    overflow: hidden;
}

.postPreview {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 1%;
}

.postTittle {
    font-weight: bold;
    align-self: center;
    margin-bottom: 1%;
}

.postImage > img {
    width: 100%;
    height: auto;
    max-height: 100%;
}