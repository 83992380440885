#MainSide {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 60%;

}

#ContactSide {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 100%;
  width: 80%;
}

#Map {
  display: flex;
  width: 48%;
  align-items: center;
  justify-content: center;
}

#Map>iframe {
  width: 100%;
  height: 90%;;
  border-radius: 50px;
}

#ContactData {
  display: flex;
  flex-direction: column;
  width: 48%;
  align-items: center;
  justify-content: center;
}

#ServicesSide {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 60%;
}

#AboutUsSide {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 60%;
}

.AbaoutUsSideImg{
  margin-top: 10%;
  max-height: 60%;
  width: 40%;
}

.AbaoutUsSideImg > img{
  max-width: 100%;
  max-height: 100%;
  border-radius: 40px;
}

.AbaoutUsSideText{
  display: flex;
  flex-direction: column;
  width:60%
}

.AbaoutUsSideTextMain{
  display: flex;
  flex-direction: column;
}

.AbaoutUsSideTextMain > p {
  font-family: "Arial", sans-serif;
  font-size: 1.1vw;
  margin-block-end: 0;
}

.AbaoutUsSideTextFooter{
  display: flex;
  flex-direction: column;
  align-items: end;
}

.AbaoutUsSideTextFooter > p{
  font-family: "Arial", sans-serif;
  font-size: 1.1vw;
  margin-block-start: 0;
  margin-block-end: 0;
}