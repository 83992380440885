.Article {
    display: flex;
    flex-direction: row;
    height: 80%;
    width: 80%;
    border-radius: 20px;
    justify-content: space-between;
  }

  .ArticleImg{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 50%;
    border-radius: 30px;
    overflow: hidden;;
  }

  .ArticleTextSection {
    display: flex;
    flex-direction: column;
    width: 45%;
    border-radius: 30px;
  }

  .ArticleTittle{
    font-weight: bold;
  }

  .ArticleImg > img {
    width: 100%;
    height: auto;
    max-height: 100%;

}