.VerticalArticle{
    width:100%;
    display: flex;
    flex-direction: column;
}

.TextField{
    width:100%;
    height:50%;
    overflow-y:auto;
    background-color: aquamarine;
}

.ImgField{
    width:100%;
    height:50%;
    overflow-x:auto;
    background-color: darkblue;
    display:flex;
    flex-direction: row;
    justify-content: space-around; /* Space between items */
    align-items: center; /* Ensure items are vertically centered */
    flex-wrap: nowrap; /* Prevent items from wrapping to the next line */
}

.imgInField{
    border: 1px solid red;
    border-radius: 30px;
    min-width: 20%; /* Ensure the images take up 20% of the parent width */
    flex-shrink: 0; /* Prevent shrinking when space is tight */
    max-width: 200px; /* Add a max-width for responsive design */
    height: 150px; /* Adjust height for consistent layout */
    background-color: white; /* Just for visual distinction */
    margin: 1%;

}